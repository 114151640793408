import * as React from 'react';
import { useRoutes } from '@core/hooks';
import StickyFilter from '@components/StickyFilter';
import { ProductCategory } from '@interface/gatsby';


type Props = {
  categories: ProductCategory[];
}

export default function ProductCategoryFilter({ categories }: Props) {
  const { route, PRODUCTS, BUNDLES } = useRoutes();

  const items = React.useMemo(() => ([
    {
      title: 'All',
      url: route(PRODUCTS),
    },
    ...(categories || []),
    null,
    {
      title: 'Bundles',
      url: route(BUNDLES),
    },
  ]), []);

  return (
    <StickyFilter
      items={items}
      align="center"
    />
  );
}
