import * as React from 'react';
import { graphql } from 'gatsby';
import { useEntities } from '@core/hooks';
import { LABEL, CART } from '@core/constants';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import EntitiesList from '@components/EntitiesList';
import ProductCategoryFilter from '@components/store/ProductCategoryFilter';
import ProductGrid from '@components/store/ProductGrid';
import ProductItem from '@components/store/ProductItem';
import { PostgresData, Product } from '@interface/gatsby';
import { PageProps } from '@interface/common';


type DataType = PostgresData<Record<'products', Product[]>>;

export default function ProductsIndexPage({ data, categories }: PageProps<DataType>) {
  const products = useEntities<Product>(data.postgres.products);

  return (
    <Main>
      <ProductCategoryFilter categories={categories!}/>
      <ProductGrid>
        <EntitiesList<Product>
          entities={products}
          render={({ item }) => (
            <ProductItem
              {...item}
              type={CART.ITEM.PRODUCTS}
              title={item.title}
              description={item.category.description}
            />
          )}
        />
      </ProductGrid>
    </Main>
  );
}

export function Head() {
  return <BaseHead title={LABEL.PRODUCTS}/>;
}

export const allProductsQuery = graphql`
    query Products {
        postgres {
            products: productsList(orderBy: CREATED_AT_DESC) {
                id
                title
                slug
                url
                price
                cover {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED, 
                            breakpoints: [750], 
                            placeholder: BLURRED, 
                            width: 750, 
                            quality: 75
                        )
                    }
                }
                category {
                    slug
                    description
                }
            }
        }
    }
`;
