import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { startCase } from 'lodash';
import clsx from 'clsx';
import { HTML } from '@core/constants';
import { useHtmlClassName } from '@core/hooks';
import Centerer from '@components/Centerer';
import ArrayList from '@components/ArrayList';
import Link from '@components/Link';
import { FilterListItem } from '@interface/common';
import * as styles from './StickyFilter.module.scss';


const FILTER_ID = 'filter';

type Props = {
  items: FilterListItem[];
  topOffset?: number;
  align?: 'left' | 'center' | 'right';
};

export default function StickyFilter({ items, topOffset = 54, align = 'left' }: Props) {
  const [isSticky, setIsSticky] = React.useState(false);

  useHtmlClassName(HTML.CLASSNAME.WITH_FILTER_OFFSET);

  const handleOnPositionChange = ({ currentPosition }: Waypoint.CallbackArgs) => {
    setIsSticky(currentPosition === Waypoint.above);
  };

  return (
    <>
      <Waypoint
        key={FILTER_ID}
        topOffset={topOffset}
        onPositionChange={handleOnPositionChange}
      />
      <section id={FILTER_ID} className={clsx(styles.container, { [styles.sticky]: isSticky })}>
        <Centerer className={clsx(styles.centerer, `text__align--${align}`)}>
          <ArrayList<FilterListItem>
            items={items}
            render={({ item }) => item === null ?
              <span className={styles.separator}>|</span> :
              <Link to={item.url} activeClassName={styles.active}>
                {startCase(item.title!)}
              </Link>
            }
          />
        </Centerer>
      </section>
      <section className={clsx({ [styles.placeholder]: isSticky })}/>
    </>
  );
}
