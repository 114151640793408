// extracted by mini-css-extract-plugin
export var caption = "_49556";
export var caption__triangle = "b0885";
export var card = "_4f729";
export var controls = "a30f9";
export var description = "_66d47";
export var image = "cb803";
export var price = "_75ca8";
export var title = "da2de";
export var widget = "_04586";
export var widget__triangle = "_48b73";