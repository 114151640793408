import * as React from 'react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { CART } from '@core/constants';
import { useCart } from '@core/hooks';
import ImageLink from '@components/ImageLink';
import Price from '@components/Price';
import RhombusSVG from '@assets/rhombus.svg';
import { Product, Bundle } from '@interface/gatsby';
import { CartItemType } from '@interface/common';
import * as styles from './ProductItem.module.scss';


type Props =
  Pick<Product | Bundle, 'id' | 'cover' | 'title' | 'url' | 'price'> & {
  type: CartItemType;
  widget?: boolean;
  description?: string;
  discount?: number;
};

export default function ProductItem({
  type,
  widget,
  id,
  cover,
  title,
  description,
  url,
  price,
  discount,
}: Props) {
  const { getDiscount, isInBundle } = useCart();

  const isProduct = type === CART.ITEM.PRODUCTS;
  const image = getImage(cover);
  const inBundle = React.useMemo(() => isProduct ? isInBundle(id) : false, [id, isInBundle]);
  const discountValue = isProduct ? inBundle ? 0 : getDiscount(id) : discount;

  return (
    <article className={clsx({
      [styles.card]: !widget,
      [styles.widget]: widget,
    })}>
      <ImageLink
        className={styles.image}
        to={url}
        image={image}
        alt={title}
        label="View more"
        useOverlay={!widget}
      />
      {widget && <RhombusSVG className={styles.widget__triangle}/>}
      <div className={styles.caption}>
        {widget && <RhombusSVG className={styles.caption__triangle}/>}
        <h3 className={styles.title}>
          <Link to={url} data-text={title}><span>{title}</span></Link>
        </h3>
        <small className={styles.description}>{description}</small>
        <div className={styles.price}>
          <Price
            value={price}
            discount={discountValue}
            showFree={isProduct}
            showDiscount
            useStyling
          />
        </div>
        {widget && (
          <div className={styles.controls}>
            <Link
              to={url}
              className="button small light outline"
            >
              View more
            </Link>
          </div>
        )}
      </div>
    </article>
  );
}
