import * as React from 'react';
import * as styles from './ProductGrid.module.scss';


type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export default function ProductItem({ children }: Props) {
  return (
    <section className={styles.grid}>
      {children}
    </section>
  );
}
